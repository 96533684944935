@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

h1,h2,h3,h4,h5,h6
  font-family 'Lato'
  font-weight bold

.title-light
  font-weight 400
  
// Add your fonts here
// 
// USAGE
//
// @font-face
//   font-family: 'FontExample';
//   src: url('FontExample.eot?#iefix') format('embedded-opentype'),  url('FontExample.otf')  format('opentype'),
//        url('FontExample.woff') format('woff'), url('FontExample.ttf')  format('truetype'), url('FontExample.svg#SackersGothicStd-Medium') format('svg');
//   font-weight: normal;
//   font-style normal;

// .fontExample
//   font-family: 'fontExample', 'sans-serif';